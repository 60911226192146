import React, { useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { requestPassword } from "../redux/AuthCRUD";

const initialValues = {
	email: "admin@demo.com",
};

const forgotPasswordSchema = Yup.object().shape({
	email: Yup.string()
		.email("Endereço de e-mail inválido")
		.min(3, "Mínimo de 3 caracteres")
		.max(50, "Maximo de 50 caracteres")
		.required("Endereço de e-mail é obrigatório"),
});

export function ForgotPassword() {
	const [loading, setLoading] = useState(false);
	const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
	const formik = useFormik({
		initialValues,
		validationSchema: forgotPasswordSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			setLoading(true);
			setHasErrors(undefined);
			setTimeout(() => {
				requestPassword(values.email)
					.then(({ data: { result } }) => {
						setHasErrors(false);
						setLoading(false);
					})
					.catch(() => {
						setHasErrors(true);
						setLoading(false);
						setSubmitting(false);
						setStatus("Algo deu errado, tente novamente");
					});
			}, 1000);
		},
	});

	return (
		<>
			<form
				className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
				noValidate
				id="kt_login_password_reset_form"
				onSubmit={formik.handleSubmit}
			>
				<div className="text-center mb-10">
					<h1 className="text-dark mb-3">Esqueceu a senha?</h1>

					<div className="text-gray-400 fw-bold fs-4">
						Insira seu endereço de e-mail para redefinir sua senha.
					</div>
				</div>

				{hasErrors === true && (
					<div className="mb-lg-15 alert alert-danger">
						<div className="alert-text font-weight-bold">
							Desculpe, alguns erros foram detectados, por favor, tente novamente.
						</div>
					</div>
				)}

				{hasErrors === false && (
					<div className="mb-10 bg-light-info p-8 rounded">
						<div className="text-info">
							E-mail enviado com sucesso. Por favor, verifique sua caixa de entrada.
						</div>
					</div>
				)}

				<div className="fv-row mb-10">
					<label className="form-label fw-bolder text-gray-900 fs-6">Email</label>
					<input
						type="email"
						placeholder=""
						autoComplete="off"
						{...formik.getFieldProps("email")}
						className={clsx(
							"form-control form-control-lg form-control-solid",
							{ "is-invalid": formik.touched.email && formik.errors.email },
							{
								"is-valid": formik.touched.email && !formik.errors.email,
							}
						)}
					/>
					{formik.touched.email && formik.errors.email && (
						<div className="fv-plugins-message-container">
							<div className="fv-help-block">
								<span role="alert">{formik.errors.email}</span>
							</div>
						</div>
					)}
				</div>

				<div className="d-flex flex-wrap justify-content-center pb-lg-0 gap-3">
					<Link to="/auth/login">
						<button
							type="button"
							id="kt_login_password_reset_form_cancel_button"
							className="btn btn-lg btn-light-primary fw-bolder"
							disabled={formik.isSubmitting || !formik.isValid}
						>
							Cancelar
						</button>
					</Link>
					<button
						type="submit"
						id="kt_password_reset_submit"
						className="btn btn-lg btn-primary fw-bolder me-4"
					>
						<span className="indicator-label">Enviar</span>
						{loading && (
							<span className="indicator-progress">
								Por favor, aguarde...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						)}
					</button>
				</div>
			</form>
		</>
	);
}
