import { useEffect } from "react";
import { useLayout } from "@/../_metronic/layout/core";

export function ExamplePage() {
	const { setTitle } = useLayout();

	useEffect(() => {
		setTitle("PÁGINA DE EXEMPLO");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<h1>PÁGINA DE EXEMPLO</h1>
		</div>
	);
}
