import { FormEvent, useState } from "react";

import axios from "axios";

import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { Card } from "@/@types/card";
import { Order } from "@/@types/order";

type Props = {
	order?: Order;
	setCard: (card: Card) => void;
};
export function LinkCardForm({ order, setCard }: Props) {
	const [isLoading, setIsLoading] = useState(false);

	async function handleSubmit(event: FormEvent) {
		event.preventDefault();

		if (!order) {
			return;
		}

		try {
			setIsLoading(true);

			const formData = new FormData(event.target as HTMLFormElement);
			formData.append("order_id", String(order.id));

			const { data } = await axios.post("/dashboard/cards/link", formData);

			setCard(data);
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			toast.error("Erro ao vincular cartão");
		}
	}

	return (
		<Form onSubmit={handleSubmit} className="d-flex flex-column gap-3">
			<h6>Produto Solicitado:</h6>
			{order?.products?.length &&
				order.products.map((product, index) => {
					return <span key={`product-${index}`}>{product.name}</span>;
				})}
			<Form.Group>
				<Form.Label htmlFor="card-number">Numero do Cartão</Form.Label>
				<Form.Control
					name="number"
					type="number"
					id="card-number"
					className="hidden-input-arrows"
				/>
			</Form.Group>
			<Form.Group className="w-100 d-flex justify-content-end">
				<Button type="submit" disabled={isLoading}>
					{isLoading && <Spinner animation="border" size="sm" className="me-2" />}
					Vincular
				</Button>
			</Form.Group>
		</Form>
	);
}
