export enum DeliveryStatus {
	InProduction = 1,
	InTransit = 2,
	Complete = 3,
	AddressNotFound = 4,
	AwaitingRetrieval = 5,
}

export function getDeliveryStatusMessage(deliveryStatus: number) {
	switch (deliveryStatus) {
		case DeliveryStatus.InProduction:
			return "Em produção";
		case DeliveryStatus.InTransit:
			return "Em trânsito";
		case DeliveryStatus.Complete:
			return "Completo";
		case DeliveryStatus.AddressNotFound:
			return "Endereço não encontrado";
		case DeliveryStatus.AwaitingRetrieval:
			return "Aguardando retirada";
	}
}
