import { FormEvent, useEffect, useState } from "react";
import "./index.css";

import Swal from "sweetalert2";
import axios from "axios";
import dayjs from "dayjs";

import Magnifier from "react-magnifier";

import { GenericPagination, PaginationMeta } from "@/@types/genericals";
import { HalfPrice } from "@/@types/halfPrice";

import { HalfPriceStatus } from "@/enums/HalfPriceStatus";

import { AiFillEye, AiFillFilePdf } from "react-icons/ai";
import { FiCheck, FiX } from "react-icons/fi";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { PaginationLinks } from "@/components/PaginationLinks";
import { BaseModal } from "@/components/BaseModal";

enum ActionType {
	Refusal = 1,
	Approval = 2,
}

type HandleInteractionParams = {
	id: number;
	action: ActionType;
};

type UpdateRequestParams = {
	id: number;
	status: HalfPriceStatus;
	reason?: string;
};

export function HalfPriceRequests() {
	const [show, setShow] = useState(false);
	const [showFiles, setShowFiles] = useState(false);

	const [requests, setRequests] = useState<HalfPrice[]>([]);
	const [currentRequest, setCurrentRequest] = useState<HalfPrice>({} as HalfPrice);
	const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
		current_page: 1,
	} as PaginationMeta);

	const [refusalReason, setRefusalReason] = useState("");

	function handleInteraction({ id, action }: HandleInteractionParams) {
		const foundRequest = requests.find((request) => request.id === id);

		if (!foundRequest) {
			console.log(`${id} not found on request list`);
			return;
		}

		setCurrentRequest(foundRequest);

		switch (action) {
			case ActionType.Approval:
				Swal.fire({
					icon: "question",
					title: "Aprovar solicitação",
					text: "Você tem certeza?",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await updateRequest({ id, status: HalfPriceStatus.Approved });
						await Swal.fire({
							icon: "success",
							title: "Sucesso!",
							text: "Solicitação aprovada.",
						});
						setShow(false);
					}
				});
				break;
			case ActionType.Refusal:
				setShow(true);
				break;
			default:
				return;
		}
	}

	async function handleRefusal(event: FormEvent) {
		event.preventDefault();
		await updateRequest({
			id: currentRequest.id,
			status: HalfPriceStatus.Reproved,
			reason: refusalReason,
		});
		await Swal.fire({
			icon: "success",
			title: "Sucesso!",
			text: "Solicitação reprovada.",
		});
		setShow(false);
	}

	function handleShowFiles(id: number) {
		const found = requests.find((halfPrice) => halfPrice.id === id);

		if (found) {
			setCurrentRequest(found);
		}

		setShowFiles(true);
	}

	async function updateRequest({ id, status, reason }: UpdateRequestParams) {
		try {
			await axios.put(`/dashboard/half-price-requests/${id}`, {
				status,
				reason,
			});

			fetchRequests();
		} catch (error: any) {
			Swal.fire({
				icon: "error",
				title: "Erro!",
				text: "Ocorreu um erro ao processar sua requisição.",
			});
		}
	}

	function changeSelectedPage(pageNumber: number) {
		setPaginationMeta({ ...paginationMeta, current_page: pageNumber });
	}

	async function fetchRequests() {
		try {
			const { data } = await axios.get<GenericPagination<HalfPrice>>(
				"/dashboard/half-price-requests",
				{ params: { page: paginationMeta.current_page } }
			);

			setPaginationMeta(data.meta);
			setRequests(data.results);
		} catch (error: any) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationMeta.current_page]);

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title>Solicitações de Desconto Estudantil</Card.Title>
				</Card.Header>
				<Table striped bordered hover responsive className="table-rounded border gy-7 gs-7">
					<thead>
						<tr>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Nome
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								CPF
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Data de Nascimento
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Data de Solicitação
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Ações
							</th>
						</tr>
					</thead>
					<tbody>
						{requests?.map((halfPrice) => (
							<tr key={halfPrice.id}>
								<td>{halfPrice.user?.name}</td>
								<td>{halfPrice.user?.identifier}</td>
								<td>{dayjs(halfPrice.user?.dob).format("DD/MM/YYYY")}</td>
								<td>{dayjs(halfPrice.created_at).format("DD/MM/YYYY")}</td>
								<td className="d-flex gap-2">
									<Button
										variant="primary"
										size="sm"
										type="button"
										onClick={() => handleShowFiles(halfPrice.id)}
									>
										<AiFillEye size={16} />
									</Button>
									<Button
										onClick={() =>
											handleInteraction({
												id: halfPrice.id,
												action: ActionType.Approval,
											})
										}
										size="sm"
										variant="success"
									>
										<FiCheck size={16} />
									</Button>
									<Button
										onClick={() =>
											handleInteraction({
												id: halfPrice.id,
												action: ActionType.Refusal,
											})
										}
										size="sm"
										variant="danger"
									>
										<FiX size={16} />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>

				<PaginationLinks
					itemsPerPage={paginationMeta.per_page}
					totalPages={paginationMeta.total}
					changeSelectedPage={changeSelectedPage}
				/>
			</Card>

			<BaseModal
				title="Arquivos"
				show={showFiles}
				onHide={() => setShowFiles(false)}
				size="xl"
				footer={<></>}
			>
				{currentRequest.id && (
					<div>
						<ul>
							<li>
								<strong>Rua/Numero: </strong>
								{currentRequest?.user?.address?.address}
							</li>
							<li>
								<strong>Bairro: </strong>
								{currentRequest?.user?.address?.district}
							</li>
							<li>
								<strong>Cidade: </strong>
								{currentRequest?.user?.address?.city?.name}
							</li>
							<li>
								<strong>CEP: </strong>
								{currentRequest?.user?.address?.zip_code}
							</li>
							<li>
								<strong>Complemento: </strong>
								{currentRequest?.user?.address?.complement}
							</li>
						</ul>

						<section>
							<h2>Documentos - Frente/Verso</h2>

							<div className="d-flex flex-stack">
								<Magnifier
									src={currentRequest.user_document_front}
									width="40%"
									mgWidth={250}
									mgHeight={250}
									mgShape="square"
								/>
								<Magnifier
									src={currentRequest.user_document_back}
									width="40%"
									mgWidth={250}
									mgHeight={250}
									mgShape="square"
								/>
							</div>
						</section>

						<hr />

						<section>
							<h2>Identificação - Selfie/Declaração</h2>

							<div className="d-flex justify-content-between">
								<Magnifier
									src={currentRequest.user_selfie}
									width="40%"
									mgWidth={250}
									mgHeight={250}
									mgShape="square"
								/>

								<div className="d-flex flex-center bg-secondary rounded student-declaration-container">
									<a
										className="d-flex flex-center text-dark fs-2"
										href={currentRequest.student_declaration}
										target="_blank"
										rel="noreferrer"
									>
										<span className="fs-4x">
											<AiFillFilePdf />
										</span>
										Clique aqui para abrir a declaração
									</a>
								</div>
							</div>
						</section>
					</div>
				)}
			</BaseModal>

			<Modal show={show} onHide={() => setShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Recusar Solicitação</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={handleRefusal}>
						<Form.Group>
							<Form.Label>Motivo:</Form.Label>
							<Form.Control
								value={refusalReason}
								onChange={(event) => setRefusalReason(event.target.value)}
								as="textarea"
								required
								minLength={1}
							></Form.Control>
						</Form.Group>

						<Form.Group>
							<Button type="submit" className="float-end">
								Salvar
							</Button>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
}
