import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";

import { DashboardWrapper } from "@/pages/Dashboard";
import { ExamplePage } from "@/pages/ExamplePage";
import { UserManagement } from "@/pages/UserManagement";
import { OrderManagement } from "@/pages/OrderManagement";
import { HalfPriceRequests } from "@/pages/HalfPriceRequests";
import { TicketPriceManagement } from "@/pages/TicketPriceManagement";
import { CooperativeManagement } from "@/pages/CooperativeManagement";
import { DeliveryManagement } from "@/pages/DeliveryManagement";

const PrivateRoutes = () => {
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path="auth/*" element={<Navigate to="/dashboard" />} />
				{/* Pages */}
				<Route path="dashboard" element={<DashboardWrapper />} />
				<Route path="pagina-de-exemplo" element={<ExamplePage />} />
				<Route path="usuarios" element={<UserManagement />} />
				<Route path="pedidos" element={<OrderManagement />} />
				<Route path="solicitacoes-estudante" element={<HalfPriceRequests />} />
				<Route path="precos" element={<TicketPriceManagement />} />
				<Route path="cooperativas" element={<CooperativeManagement />} />
				<Route path="entregas" element={<DeliveryManagement />} />
				{/* Page Not Found */}
				<Route path="*" element={<Navigate to="/error/404" />} />
			</Route>
		</Routes>
	);
};

export { PrivateRoutes };
