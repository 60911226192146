export enum CardType {
	Common = 0,
	HalfPrice = 1,
	Gratuity = 2,
}

export function getCardTypeMessage(cardType: CardType): string {
	switch (cardType) {
		case CardType.Common:
			return "Comum";
		case CardType.HalfPrice:
			return "Estudante";
		case CardType.Gratuity:
			return "Gratuidade";
	}
}
