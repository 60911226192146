import { FormEvent, useEffect, useState } from "react";

import axios from "axios";
import Swal from "sweetalert2";

import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { FaEdit } from "react-icons/fa";

import { GenericPagination, PaginationMeta, SelectOptionsProps } from "@/@types/genericals";
import { TicketPrice } from "@/@types/ticketPrice";
import { BaseModal } from "@/components/BaseModal";
import { CitySelect } from "@/components/CitySelect";
import { PaginationLinks } from "@/components/PaginationLinks";

export function TicketPriceManagement() {
	const [show, setShow] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
		current_page: 1,
	} as PaginationMeta);

	const [ticketPrices, setTicketPrices] = useState<TicketPrice[]>([]);
	const [currentTicketPrice, setCurrentTicketPrice] = useState<TicketPrice>();

	const [cityResults, setCityResults] = useState<SelectOptionsProps[]>([]);
	const [currentOrigin, setCurrentOrigin] = useState<SelectOptionsProps>();
	const [currentDestination, setCurrentDestination] = useState<SelectOptionsProps>();

	async function fetchPrices() {
		try {
			const { data } = await axios.get<GenericPagination<TicketPrice>>("/ticket-prices", {
				params: { page: paginationMeta.current_page },
			});
			setPaginationMeta(data.meta);
			setTicketPrices(data.results);
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				console.error(error.response);
			} else {
				console.error(error);
			}

			Swal.fire({
				title: "Erro",
				text: "Erro ao atualizar.",
				icon: "error",
			});
		}
	}

	async function handleFormSubmit(event: FormEvent) {
		event.preventDefault();
		const formData = new FormData(event.target as HTMLFormElement);

		const data = currentTicketPrice?.id
			? {
				origin_id: currentTicketPrice.origin_id,
				destination_id: currentTicketPrice.destination_id,
				price: formData.get("price"),
			}
			: formData;

		try {
			await axios.post("/dashboard/ticket-prices", data);
			setPaginationMeta({ current_page: 0 } as PaginationMeta);

			Swal.fire({
				title: "Sucesso",
				text: "Registro atualizado",
				icon: "success",
			});

			setShow(false);
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				console.error(error.response);
			} else {
				console.error(error);
			}

			Swal.fire({
				title: "Erro",
				text: "Erro ao atualizar.",
				icon: "error",
			});
		}
	}

	function changeSelectedPage(pageNumber: number): void {
		setPaginationMeta({ ...paginationMeta, current_page: pageNumber });
	}

	function handleEditButton(id: number) {
		const found = ticketPrices.find((ticketPrice) => ticketPrice.id === id);
		if (!found || !found.origin || !found.destination) {
			return;
		}

		const editResults = [
			{
				value: found.origin.id,
				label: found.origin.name,
			},
			{
				value: found.destination.id,
				label: found.destination.name,
			},
		];

		setCurrentOrigin(editResults[0]);
		setCurrentDestination(editResults[1]);
		setCityResults(editResults);
		setCurrentTicketPrice(found);
		setIsEditing(true);
		setShow(true);
	}

	function handleModalClose() {
		setIsEditing(false);
		setShow(false);
		setCityResults([]);
		setCurrentOrigin(undefined);
		setCurrentDestination(undefined);
		setCurrentTicketPrice({} as TicketPrice);
	}

	useEffect(() => {
		fetchPrices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationMeta.current_page]);

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className="d-flex flex-stack w-100">
						<span>Gerenciamento de Preço das Passagens</span>
						<Button onClick={() => setShow(true)}>Novo Registro</Button>
					</Card.Title>
				</Card.Header>

				<Card.Body>
					<Table striped bordered hover responsive>
						<thead>
							<tr>
								<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
									Origem
								</th>
								<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
									Destino
								</th>
								<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
									Preço
								</th>
								<th
									className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200"
									style={{ maxWidth: "fit-content" }}
								>
									Ações
								</th>
							</tr>
						</thead>
						<tbody>
							{ticketPrices?.map((ticketPrice) => (
								<tr className="align-middle" key={`ticket-${ticketPrice.id}`}>
									<td>{ticketPrice.origin?.name}</td>
									<td>{ticketPrice.destination.name}</td>
									<td>{ticketPrice.price}</td>
									<td className="d-flex gap-2">
										<Button
											variant="warning"
											size="sm"
											onClick={() => handleEditButton(ticketPrice.id)}
										>
											<FaEdit size={18} />
										</Button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<PaginationLinks
						itemsPerPage={10}
						totalPages={paginationMeta.total}
						changeSelectedPage={changeSelectedPage}
					/>
				</Card.Body>
			</Card>
			<BaseModal title="Adicionar preço" show={show} onHide={handleModalClose} footer={<></>}>
				<Form onSubmit={handleFormSubmit}>
					<Row className="gap-2">
						<Form.Group>
							<Form.Label htmlFor="origin_city_id">Cidade Origem</Form.Label>
							<CitySelect
								value={currentOrigin}
								setValue={setCurrentOrigin}
								results={cityResults}
								setResults={setCityResults}
								isDisabled={isEditing}
								name="origin_id"
								id="origin_city_id"
								placeholder="Escolha a cidade de origem"
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="destination_city_id">Cidade Destino</Form.Label>
							<CitySelect
								value={currentDestination}
								setValue={setCurrentDestination}
								results={cityResults}
								setResults={setCityResults}
								isDisabled={isEditing}
								name="destination_id"
								id="destination_city_id"
								placeholder="Escolha a cidade de destino"
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label htmlFor="price">Preço</Form.Label>
							<Form.Control
								defaultValue={currentTicketPrice?.price}
								name="price"
								type="number"
								step="0.01"
								id="price"
								placeholder="valor da passagem"
								className="hidden-input-arrows"
							/>
						</Form.Group>
						<FormGroup className="d-flex justify-content-end w-100">
							<Button type="submit">Salvar</Button>
						</FormGroup>
					</Row>
				</Form>
			</BaseModal>
		</>
	);
}
