import { useEffect, useRef, useState } from "react";

import axios from "axios";
import dayjs from "dayjs";
import Magnifier from "react-magnifier";
import InputMask from "react-input-mask";
import { AiFillCreditCard, AiFillEye, AiFillFileText, AiFillFilePdf } from "react-icons/ai";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tooltip from "react-bootstrap/Tooltip";

import { GenericPagination, PaginationMeta } from "@/@types/genericals";
import { OrderStatusMessages } from "@/enums/OrderStatus";
import { Card as CardType } from "@/@types/card";
import { HalfPrice } from "@/@types/halfPrice";
import { Order } from "@/@types/order";

import { PaginationLinks } from "@/components/PaginationLinks";
import { LinkCardForm } from "@/components/Forms/LinkCard";
import { BaseModal } from "@/components/BaseModal";
import { CopyableListItem } from "@/components/CopyableListItem";

import "./styles.css";

export function OrderManagement() {
	const [orders, setOrders] = useState<Order[]>([]);
	const [currentOrder, setCurrentOrder] = useState<Order>();

	const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
		current_page: 1,
	} as PaginationMeta);

	const [card, setCard] = useState<CardType>({} as CardType);
	const [show, setShow] = useState(false);

	const [isModalLoading, setIsModalLoading] = useState(true);
	const handleOpen = () => setShow(true);
	const handleClose = () => setShow(false);

	const handleOpenDetails = () => setShowDetails(true);
	const handleCloseDetails = () => setShowDetails(false);

	const [showDetails, setShowDetails] = useState(false);

	const [currentRequest, setCurrentRequest] = useState<HalfPrice>({} as HalfPrice);
	const [showFiles, setShowFiles] = useState(false);

	const inputMaskIdentifierRef = useRef<HTMLInputElement | null>(null);
	const inputMaskPhoneRef = useRef<HTMLInputElement | null>(null);

	async function fetchOrders() {
		try {
			const { data } = await axios.get<GenericPagination<Order>>(
				"/dashboard/orders/pending-card",
				{ params: { page: paginationMeta.current_page } }
			);

			setPaginationMeta(data.meta);
			setOrders(data.results);
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				console.log(error?.response?.data);
			}
			console.log(error);
		}
	}

	function handleShowFiles(id: number) {
		const found = orders.find((order) => order.id === id);

		if (found?.user?.last_half_price_request) {
			setCurrentRequest(found.user.last_half_price_request);
		}

		setShowFiles(true);
	}

	async function fetchUserCard(userId: number) {
		const { data } = await axios.get(`/dashboard/users/${userId}/active-card`);
		setCard(data);
		setIsModalLoading(false);
	}

	async function handleCardVisualization(orderId: number) {
		handleOpen();
		setCurrentOrder(orders.find((order) => order.id === orderId));
	}

	async function handleDetailsVisualization(orderId: number) {
		handleOpenDetails();
		setCurrentOrder(orders.find((order) => order.id === orderId));
	}

	function changeSelectedPage(pageNumber: number): void {
		setPaginationMeta({ ...paginationMeta, current_page: pageNumber });
	}

	useEffect(() => {
		fetchOrders();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationMeta.current_page]);

	useEffect(() => {
		setIsModalLoading(true);
		if (currentOrder?.id) {
			fetchUserCard(currentOrder?.user_id);
		}
	}, [currentOrder]);

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title>Pedidos de Cartões</Card.Title>
				</Card.Header>
				<Table striped bordered hover responsive className="table-rounded border gy-7 gs-7">
					<thead>
						<tr>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								ID
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Passageiro
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Status
							</th>
							<th>#</th>
						</tr>
					</thead>
					<tbody>
						{orders?.map((order, index) => (
							<tr key={`order-${index}`}>
								<td>{order.id}</td>
								<td>
									{order.user?.name} / {order.user?.identifier}
								</td>
								<td>{OrderStatusMessages[order.status]}</td>
								<td>
									<div className="d-flex gap-3">
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="main_address">Vincular cartão</Tooltip>
											}
										>
											<Button
												onClick={() => {
													handleCardVisualization(order.id);
												}}
												variant="success"
											>
												<AiFillCreditCard />
											</Button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="main_address">Ver Pedido</Tooltip>
											}
										>
											<Button
												onClick={() => {
													handleDetailsVisualization(order.id);
												}}
											>
												<AiFillEye />
											</Button>
										</OverlayTrigger>
										{order.user?.last_half_price_request && (
											<OverlayTrigger
												placement="bottom"
												overlay={
													<Tooltip id="main_address">
														Ver documentação
													</Tooltip>
												}
											>
												<Button
													onClick={() => handleShowFiles(order.id)}
													variant="info"
												>
													<AiFillFileText />
												</Button>
											</OverlayTrigger>
										)}
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<PaginationLinks
					itemsPerPage={paginationMeta.per_page}
					totalPages={paginationMeta.total}
					changeSelectedPage={changeSelectedPage}
				/>
			</Card>

			<div className="d-none" id="hidden-masks">
				<InputMask
					inputRef={(inputRef) => (inputMaskIdentifierRef.current = inputRef)}
					mask="999.999.999-99"
					className="span-inputmask"
					value={currentOrder?.user?.identifier}
					readOnly
				/>

				<InputMask
					inputRef={(inputRef) => (inputMaskPhoneRef.current = inputRef)}
					mask="+99 (99) 99999-9999"
					className="span-inputmask"
					value={currentOrder?.user?.phone}
					readOnly
				/>
			</div>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>{card ? "Cartão do Usuário" : "Vincular Cartão"}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{isModalLoading ? (
						<div className="d-flex flex-center">
							<Spinner animation="border" />
						</div>
					) : card.id ? (
						card.number
					) : (
						<LinkCardForm order={currentOrder} setCard={setCard} />
					)}
				</Modal.Body>
			</Modal>

			<Modal show={showDetails} size="lg" onHide={handleCloseDetails}>
				<Modal.Header closeButton>
					<Modal.Title>Detalhes do endereço</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Tab.Container id="list-group-tabs-example" defaultActiveKey="#passenger">
						<Row>
							<Col sm={4}>
								<ListGroup>
									<ListGroup.Item action href="#passenger">
										Passageiro
									</ListGroup.Item>
									<ListGroup.Item action href="#address">
										Endereço
									</ListGroup.Item>
								</ListGroup>
							</Col>
							<Col sm={8}>
								<Tab.Content>
									<Tab.Pane eventKey="#passenger">
										<ul>
											<CopyableListItem
												label="Nome: "
												value={currentOrder?.user?.name}
											/>
											<CopyableListItem
												label="Data de Nascimento: "
												value={dayjs(currentOrder?.user?.dob).format(
													"DD/MM/YYYY"
												)}
											/>
											<CopyableListItem
												label="CPF: "
												value={inputMaskIdentifierRef.current?.value}
											/>
											<CopyableListItem
												label="Telefone: "
												value={inputMaskPhoneRef.current?.value.slice(4)}
											/>
											<CopyableListItem
												label="Email: "
												value={currentOrder?.user?.email}
											/>
										</ul>
									</Tab.Pane>
									<Tab.Pane eventKey="#address">
										<ul>
											<CopyableListItem
												label="Rua/Numero: "
												value={currentOrder?.user?.address?.address}
											/>
											<CopyableListItem
												label="Bairro: "
												value={currentOrder?.user?.address?.district}
											/>
											<CopyableListItem
												label="Estado: "
												value={currentOrder?.user?.address?.state?.name}
											/>
											<CopyableListItem
												label="Cidade: "
												value={currentOrder?.user?.address?.city?.name}
											/>
											<CopyableListItem
												label="CEP: "
												value={currentOrder?.user?.address?.zip_code}
											/>
											<CopyableListItem
												label="Complemento: "
												value={currentOrder?.user?.address?.complement}
											/>
										</ul>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Modal.Body>
			</Modal>

			<BaseModal
				title="Arquivos"
				show={showFiles}
				onHide={() => setShowFiles(false)}
				size="xl"
				footer={<></>}
			>
				{currentRequest.id && (
					<div>
						<section>
							<h2>Documentos - Frente/Verso</h2>

							<div className="d-flex flex-stack">
								<Magnifier
									src={
										currentRequest.full_url +
										"/" +
										currentRequest.user_document_front
									}
									width="40%"
									mgWidth={250}
									mgHeight={250}
									mgShape="square"
								/>
								<Magnifier
									src={
										currentRequest.full_url +
										"/" +
										currentRequest.user_document_back
									}
									width="40%"
									mgWidth={250}
									mgHeight={250}
									mgShape="square"
								/>
							</div>
						</section>

						<hr />

						<section>
							<h2>Identificação - Selfie/Declaração</h2>

							<div className="d-flex justify-content-between">
								<Magnifier
									src={currentRequest.full_url + "/" + currentRequest.user_selfie}
									width="40%"
									mgWidth={250}
									mgHeight={250}
									mgShape="square"
								/>

								<div className="d-flex flex-center bg-secondary rounded student-declaration-container">
									<a
										className="d-flex flex-center text-dark fs-2"
										href={
											currentRequest.full_url +
											"/" +
											currentRequest.student_declaration
										}
										target="_blank"
										rel="noreferrer"
									>
										<span className="fs-4x">
											<AiFillFilePdf />
										</span>
										Clique aqui para abrir a declaração
									</a>
								</div>
							</div>
						</section>
					</div>
				)}
			</BaseModal>
		</>
	);
}
