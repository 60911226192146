import { ReactNode } from "react";
import { MdOutlineContentCopy } from "react-icons/md";

type CopyableListItemProps = {
	label: string;
	value?: ReactNode;
	valueToCopy?: string;
};

export function CopyableListItem({ label, value, valueToCopy }: CopyableListItemProps) {
	function handleCopyValueToClipboard() {
		if (valueToCopy) {
			navigator.clipboard.writeText(valueToCopy);
			return;
		}

		if (value && typeof value === "string") {
			navigator.clipboard.writeText(value);
			return;
		}
	}

	return (
		<li>
			<strong>{label}</strong>
			<span>{value}</span>
			<button className="btn btn-sm btn-hover-rise p-0" onClick={handleCopyValueToClipboard}>
				<span className="ms-3 fs-3">
					<MdOutlineContentCopy />
				</span>
			</button>
		</li>
	);
}
