import { useEffect, useState } from "react";

import axios from "axios";

import ListGroup from "react-bootstrap/ListGroup";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import { AiFillEye } from "react-icons/ai";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

import { GenericPagination, PaginationMeta } from "@/@types/genericals";
import { PaginationLinks } from "@/components/PaginationLinks";
import { Delivery } from "@/@types/delivery";
import { getCardTypeMessage } from "@/enums/CardType";
import { DeliveryStatus, getDeliveryStatusMessage } from "@/enums/DeliveryStatus";
import Swal from "sweetalert2";

export function DeliveryManagement() {
	const [deliveries, setDeliveries] = useState<Delivery[]>([]);
	const [currentDelivery, setcurrentDelivery] = useState<Delivery>();

	const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>({
		current_page: 1,
	} as PaginationMeta);

	const [show, setShow] = useState(false);

	const [isModalLoading, setIsModalLoading] = useState(true);
	const handleOpen = () => setShow(true);
	const handleClose = () => setShow(false);

	function formatPhoneNumber(phone: string) {
		if (phone) {
			phone = phone.replace(/\D/g, ""); //Remove tudo o que não é dígito
			phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
			phone = phone.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
		}

		return phone;
	}

	async function fetchDeliveries() {
		try {
			const { data } = await axios.get<GenericPagination<Delivery>>("/dashboard/deliveries", {
				params: { page: paginationMeta.current_page },
			});

			setPaginationMeta(data.meta);
			setDeliveries(data.results);
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				console.log(error?.response?.data);
			}
			console.log(error);
		}
	}

	async function handleCardVisualization(deliveryId: Number) {
		handleOpen();
		setcurrentDelivery(deliveries.find((delivery) => delivery.id === deliveryId));
	}

	function changeSelectedPage(pageNumber: number): void {
		setPaginationMeta({ ...paginationMeta, current_page: pageNumber });
	}

	function handleUpdateDeliveryStatus(deliveryId: number, status: number) {
		Swal.mixin({
			toast: true,
			position: "top-right",
			iconColor: "white",
			customClass: {
				popup: "colored-popup",
			},
			showConfirmButton: false,
			timer: 2000,
			timerProgressBar: true,
		});

		axios
			.put(`/dashboard/delivery/${deliveryId}`, {
				status,
			})
			.then(() => {
				//TODO: MAKE COMPONENT
				Swal.fire({
					icon: "success",
					title: "Successo!",
					text: "Status da entrega atualizado com sucesso!",
				});
			})
			.catch(() => {
				Swal.fire({
					icon: "error",
					title: "Erro!",
					text: "Ocorreu um erro ao atualizar o status da entrega!",
				});
			});
	}

	useEffect(() => {
		fetchDeliveries();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationMeta.current_page]);

	useEffect(() => {
		setIsModalLoading(true);
	}, [currentDelivery]);

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title>Entregas</Card.Title>
				</Card.Header>
				<Table striped bordered hover responsive className="table-rounded border gy-7 gs-7">
					<thead>
						<tr>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								ID
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Passageiro
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Cartão
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Tipo
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Status
							</th>
							<th>#</th>
						</tr>
					</thead>
					<tbody>
						{deliveries?.map((delivery, index) => (
							<tr key={`delivery-${index}`} className="align-middle">
								<td>{delivery.id}</td>
								<td>
									{delivery.passenger.name} / {delivery.passenger?.identifier}
								</td>
								<td>{delivery.card.number}</td>
								<td>{getCardTypeMessage(delivery.card.type)}</td>
								<td>
									<Form.Select
										onChange={(event) =>
											handleUpdateDeliveryStatus(
												delivery.id,
												Number(event.target.value)
											)
										}
									>
										{(
											Object.keys(DeliveryStatus) as Array<
												keyof typeof DeliveryStatus
											>
										).map((status) => {
											const numericStatus = Number(status);
											if (isNaN(numericStatus)) {
												return false;
											}

											return (
												<option
													value={status}
													selected={numericStatus === delivery.status}
												>
													{getDeliveryStatusMessage(numericStatus)}
												</option>
											);
										})}
									</Form.Select>
								</td>
								<td>
									<Button
										onClick={() => {
											handleCardVisualization(delivery.id);
										}}
									>
										<AiFillEye />
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<PaginationLinks
					itemsPerPage={paginationMeta.per_page}
					totalPages={paginationMeta.total}
					changeSelectedPage={changeSelectedPage}
				/>
			</Card>
			<Modal show={show} size="lg" onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Detalhes do endereço</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{!isModalLoading ? (
						<div className="d-flex flex-center">
							<Spinner animation="border" />
						</div>
					) : (
						<>
							<Tab.Container
								id="list-group-tabs-example"
								defaultActiveKey="#passenger"
							>
								<Row>
									<Col sm={4}>
										<ListGroup>
											<ListGroup.Item action href="#passenger">
												Passageiro
											</ListGroup.Item>
											<ListGroup.Item action href="#address">
												Endereço
											</ListGroup.Item>
										</ListGroup>
									</Col>
									<Col sm={8}>
										<Tab.Content>
											<Tab.Pane eventKey="#passenger">
												<ul>
													<li>
														<strong>Nome: </strong>{" "}
														{currentDelivery?.passenger.name}
													</li>
													<li>
														<strong>CPF: </strong>{" "}
														{currentDelivery?.passenger.identifier}
													</li>
													<li>
														<strong>Telefone: </strong>
														{formatPhoneNumber(
															currentDelivery?.passenger
																.phone as string
														)}
													</li>
													<li>
														<strong>Email: </strong>
														{currentDelivery?.passenger.email}
													</li>
												</ul>
											</Tab.Pane>
											<Tab.Pane eventKey="#address">
												<ul>
													<li>
														<strong>Rua/Numero: </strong>
														{currentDelivery?.address.address}
													</li>
													<li>
														<strong>Bairro: </strong>
														{currentDelivery?.address.district}
													</li>
													<li>
														<strong>Cidade: </strong>
														{currentDelivery?.address.city?.name}
													</li>
													<li>
														<strong>CEP: </strong>
														{currentDelivery?.address.zip_code}
													</li>
													<li>
														<strong>Complemento: </strong>
														{currentDelivery?.address.complement}
													</li>
												</ul>
											</Tab.Pane>
										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>
						</>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
}
