export enum OrderStatus {
	AWAITING_PAYMENT = 0,
	AWAITING_CONCLUSION = 1,
	FINISHED = 2,
}

export const OrderStatusMessages = {
	[OrderStatus.AWAITING_PAYMENT]: "Aguardando Pagamento",
	[OrderStatus.AWAITING_CONCLUSION]: "Aguardando Conclusão",
	[OrderStatus.FINISHED]: "Finalizado",
};
