import BaseSelect, { Props } from "react-select";

export function Select({ ...rest }: Props) {
	return (
		<BaseSelect
			loadingMessage={() => "Carregando..."}
			noOptionsMessage={() => "Sem resultados"}
			placeholder=""
			{...rest}
		/>
	);
}
