export enum UserStatus {
	HasNotBeenRequested = 0,
	AwaitingPayment = 1,
	PaymentReceived = 2,
	PaymentRefused = 3,
	CardInProduction = 4,
	CardInTransit = 5,
	CardAwaitingRetrieval = 6,
	CardReceived = 7,
	CardReceivementConfirmedByUser = 8,
	CardNotSentBecauseOfInvalidAddress = 9,
	StudentRequestAwaitingDocumentReview = 10,
	StudentRequestDocumentReviewFailed = 11,
	StudentRequestDocumentReviewPassed = 12,
}

export const UserStatusMessages = {
	[UserStatus.HasNotBeenRequested]: "Não solicitado",
	[UserStatus.AwaitingPayment]: "Aguardando pagamento",
	[UserStatus.PaymentReceived]: "Pagamento recebido",
	[UserStatus.PaymentRefused]: "Pagamento recusado",
	[UserStatus.CardInProduction]: "Cartão em produção",
	[UserStatus.CardInTransit]: "Cartão em trânsito",
	[UserStatus.CardAwaitingRetrieval]: "Cartão aguardando retirada",
	[UserStatus.CardReceived]: "Cartão recebido",
	[UserStatus.CardReceivementConfirmedByUser]: "Recebimento do cartão confirmado pelo usuário",
	[UserStatus.CardNotSentBecauseOfInvalidAddress]:
		"Cartão não enviado devido a endereço inválido",
	[UserStatus.StudentRequestAwaitingDocumentReview]:
		"Solicitação de estudante aguardando revisão de documento",
	[UserStatus.StudentRequestDocumentReviewFailed]:
		"Revisão de documento da solicitação de estudante falhou",
	[UserStatus.StudentRequestDocumentReviewPassed]:
		"Revisão de documento da solicitação de estudante aprovada",
};
