import { FaUsers } from "react-icons/fa";
import { AsideMenuItem } from "./AsideMenuItem";

export function AsideMenuMain() {
	return (
		<>
			<AsideMenuItem
				to="/dashboard"
				icon="/media/icons/duotune/art/art002.svg"
				title="DASHBOARD"
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/usuarios"
				icon={<FaUsers />}
				title="USUÁRIOS"
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/pedidos"
				icon="/media/icons/duotune/art/art002.svg"
				title="PEDIDOS"
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/solicitacoes-estudante"
				icon="/media/icons/duotune/art/art002.svg"
				title="SOLICITAÇÔES DE ESTUDANTE"
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/precos"
				icon="/media/icons/duotune/art/art002.svg"
				title="PREÇOS"
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/cooperativas"
				icon="/media/icons/duotune/art/art002.svg"
				title="COOPERATIVAS"
				fontIcon="bi-app-indicator"
			/>

			<AsideMenuItem
				to="/entregas"
				icon="/media/icons/duotune/art/art002.svg"
				title="ENTREGAS"
				fontIcon="bi-app-indicator"
			/>
		</>
	);
}
