import { useEffect, useState } from "react";

import axios from "axios";
import dayjs from "dayjs";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import { User } from "@/@types/user";
import { UserStatusMessages } from "@/enums/UserStatus";

export function UserManagement() {
	const [users, setUsers] = useState<User[]>([]);

	async function fetchUsers() {
		try {
			const { data } = await axios.get("/dashboard/users");
			setUsers(data);
		} catch (error: any) {
			console.log(error);
		}
	}

	useEffect(() => {
		fetchUsers();
	}, []);
	return (
		<Card>
			<Card.Header>
				<Card.Title>Usuários</Card.Title>
			</Card.Header>

			<Table striped bordered hover responsive className="table-rounded border gy-7 gs-7">
				<thead>
					<tr>
						<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							ID
						</th>
						<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							Nome
						</th>
						<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							CPF / CNS
						</th>
						<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							Email
						</th>
						<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							Data de Nascimento
						</th>
						<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
							Status
						</th>
					</tr>
				</thead>
				<tbody>
					{users.map((user) => {
						return (
							<tr>
								<td>{user.id}</td>
								<td>{user.name}</td>
								<td>{user.identifier}</td>
								<td>{user.email}</td>
								<td>{dayjs(user.dob).format("DD/MM/YYYY")}</td>
								<td>
									{user.status
										? UserStatusMessages[user.status]
										: "Sem Informações"}
								</td>
							</tr>
						);
					})}
				</tbody>
			</Table>
		</Card>
	);
}
