import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

type PaginatedLinksProps = {
	itemsPerPage: number;
	changeSelectedPage: (value: number) => void;
	totalPages: number;
};

type PageChangeEvent = {
	selected: number;
};

export function PaginationLinks({
	itemsPerPage,
	changeSelectedPage,
	totalPages,
}: PaginatedLinksProps) {
	const pages = Array.from({ length: totalPages }, (_, index) => index + 1);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);

	useEffect(() => {
		setPageCount(Math.ceil(pages.length / itemsPerPage));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemOffset, itemsPerPage, totalPages]);

	const handlePageClick = (event: PageChangeEvent) => {
		const newOffset = event.selected + 1;

		changeSelectedPage(newOffset);
		setItemOffset(newOffset);
	};

	return (
		<ReactPaginate
			containerClassName="pagination"
			pageClassName="page-item"
			pageLinkClassName="page-link"
			activeClassName="active"
			nextClassName="page-item next"
			nextLinkClassName="page-link"
			previousClassName="page-item previous"
			previousLinkClassName="page-link"
			breakLabel="..."
			nextLabel={<i className="next"></i>}
			previousLabel={<i className="previous"></i>}
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
		/>
	);
}
