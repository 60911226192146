import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { AsideDefault } from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { ScrollTop } from "./components/ScrollTop";
import { Content } from "./components/Content";
import { PageDataProvider } from "./core";
import { useLocation } from "react-router-dom";
import { DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan } from "../partials";
import { MenuComponent } from "../assets/ts/components";

const MasterLayout = () => {
	const location = useLocation();
	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization();
		}, 500);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			MenuComponent.reinitialization();
		}, 500);
	}, [location.key]);

	return (
		<PageDataProvider>
			<div className="page d-flex flex-row flex-column-fluid">
				<AsideDefault />
				<div className="wrapper d-flex flex-column flex-row-fluid pt-20" id="kt_wrapper">
					<HeaderWrapper />

					<div id="kt_content" className="content d-flex flex-column">
						<div className="post d-flex flex-column-fluid" id="kt_post">
							<Content>
								<Outlet />
							</Content>
						</div>
					</div>
					<Footer />
				</div>
			</div>

			<ActivityDrawer />
			<DrawerMessenger />

			<Main />
			<InviteUsers />
			<UpgradePlan />
			<ScrollTop />
		</PageDataProvider>
	);
};

export { MasterLayout };
