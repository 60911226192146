import { useState, useEffect } from "react";
import axios from "axios";
import { Props } from "react-select";

import { Select } from "@/components/Select";
import { City } from "@/@types/city";
import { SelectOptionsProps } from "@/@types/genericals";

type CitySelectProps = {
	value: SelectOptionsProps | undefined;
	setValue: (value: SelectOptionsProps) => void;
	results: SelectOptionsProps[];
	setResults: (results: SelectOptionsProps[]) => void;
} & Props;

export function CitySelect({ value, setValue, results, setResults, ...rest }: CitySelectProps) {
	const [searchTerm, setSearchTerm] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			if (!searchTerm) {
				setIsLoading(false);
				return [];
			}
			setIsLoading(true);
			const { data } = await axios.get("/cities/search-by-name", {
				params: { query: searchTerm },
			});
			const transformed = data.results.map((city: City) => ({
				value: city.id,
				label: city.name,
			}));
			setResults(transformed);
			setIsLoading(false);
		}, 400);
		return () => {
			setIsLoading(true);
			clearTimeout(delayDebounceFn);
		};
	}, [searchTerm, setResults]);

	return (
		<Select
			value={value}
			onChange={(newValue) => setValue(newValue as SelectOptionsProps)}
			onInputChange={(value) => setSearchTerm(value)}
			isLoading={isLoading}
			options={results}
			loadingMessage={() => "Carregando..."}
			noOptionsMessage={() => "Sem resultados"}
			{...rest}
		/>
	);
}
