import { FormEvent, useEffect, useState } from "react";

import axios from "axios";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Cooperative } from "@/@types/cooperative";
import { AiFillEye, AiOutlineCrown, AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { BaseModal } from "@/components/BaseModal";
import { Address } from "@/@types/address";
import { State } from "@/@types/state";
import { City } from "@/@types/city";

import InputMask, { Props } from "react-input-mask";
import Swal from "sweetalert2";

export function CooperativeManagement() {
	const [cooperatives, setCooperative] = useState<Cooperative[]>([]);
	const [cooperativeAddresses, setCooperativeAddresses] = useState<Address[]>([]);
	const [states, setStates] = useState<State[]>([]);
	const [cities, setCities] = useState<City[]>([]);

	const [currentState, setCurrentState] = useState(6);
	const [currentCooperative, setCurrentCooperative] = useState<Cooperative>();

	const [show, setShow] = useState(false);
	const [isCreating, setIsCreating] = useState(false);
	const [isNewAddress, setIsNewAddress] = useState(false);

	const handleOpen = () => setShow(true);

	const handleClose = () => {
		setShow(false);
		setIsCreating(false);
		setIsNewAddress(false);
	};

	async function fetchCooperatives() {
		try {
			const { data } = await axios.get("/dashboard/cooperatives");
			setCooperative(data.results);
			handleClose();
		} catch (error: any) {
			console.log(error);
		}
	}

	async function fetchAddresses() {
		if (!currentCooperative) return;

		try {
			const { data } = await axios.get(`/cooperatives/${currentCooperative?.id}/addresses`);
			setCooperativeAddresses(data.results);
		} catch (error: any) {
			console.log(error);
		}
	}

	async function fetchStates() {
		try {
			const { data } = await axios.get("/states");
			setStates(data.items);
		} catch (error: any) {
			console.log(error);
		}
	}

	async function fetchCities() {
		try {
			const { data } = await axios.get(`/states/${currentState}/cities`);
			setCities(data.results);
		} catch (error: any) {
			console.log(error);
		}
	}

	function getStateName(stateId: number) {
		return states.find((state) => state.id === stateId)?.name;
	}

	function handleCooperativeVisualization(cooperativeId: number) {
		handleOpen();
		setCurrentCooperative(cooperatives.find((cooperative) => cooperative.id === cooperativeId));
	}

	async function handleNewCooperativeAddress(cooperativeId: number) {
		handleCooperativeVisualization(cooperativeId);
		setIsNewAddress(true);
	}

	async function handleOpenCreateModal() {
		handleOpen();
		setCurrentCooperative(undefined);
		setIsCreating(true);
	}

	async function handleNewCooperative(event: FormEvent) {
		event.preventDefault();
		const form = event.currentTarget as HTMLFormElement;
		const formData = new FormData(form);

		let endpoint;

		if (isNewAddress) {
			endpoint = "/address";
			formData.append("cooperative_id", currentCooperative?.id.toString() || "0");
			formData.append("country", "Brazil");
		} else {
			endpoint = "/dashboard/cooperatives";
		}

		try {
			await axios.post(endpoint, formData);
			fetchCooperatives();
			handleClose();

			Swal.mixin({
				toast: true,
				target: document.body,
				position: "top-end",
				title: "Sucesso!",
				text: "Endereço deletado.",
				icon: "success",
			}).fire();
		} catch (error: any) {
			console.log(error);
		}
	}

	async function handleDeleteAddress(addressId: number) {
		await axios
			.delete(`/address/${addressId}/delete`)
			.then(() => {
				Swal.mixin({
					toast: true,
					target: document.body,
					position: "top-end",
					title: "Sucesso!",
					text: "Endereço deletado.",
					icon: "success",
				}).fire();
			})
			.catch(() => {
				Swal.mixin({
					toast: true,
					target: document.body,
					position: "top-end",
					title: "Oops...",
					text: "Não foi possível deletar o endereço",
					icon: "error",
				}).fire();
			});

		fetchCooperatives();
		handleClose();
	}

	useEffect(() => {
		fetchCooperatives();
		fetchStates();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchCities();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentState]);

	useEffect(() => {
		fetchAddresses();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentCooperative]);

	function PhoneInput(props: Props) {
		return (
			<InputMask
				mask="(99)9 9999-9999"
				className="form-control"
				name="phone"
				disabled={!isCreating}
				onChange={props.onChange}
				value={props.value}
			/>
		);
	}

	return (
		<>
			<Card>
				<Card.Header>
					<Card.Title className="d-flex flex-stack w-100">
						<span>COOPERATIVAS</span>
						<Button variant="primary" onClick={handleOpenCreateModal}>
							Nova Cooperativa
						</Button>
					</Card.Title>
				</Card.Header>
				<Table striped bordered hover responsive className="table-rounded border gy-7 gs-7">
					<thead>
						<tr>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								ID
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Razão Social
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Nome Fantasia
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								CNPJ
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Telefone
							</th>
							<th className="fw-bold fs-6 text-gray-800 text-uppercase border-bottom border-gray-200">
								Ações
							</th>
						</tr>
					</thead>
					<tbody>
						{cooperatives?.map((cooperative) => {
							return (
								<tr className="align-middle" key={cooperative.id}>
									<td>{cooperative.id}</td>
									<td>{cooperative.corporate_name}</td>
									<td>{cooperative.trading_name}</td>
									<td>{cooperative.identifier}</td>
									<td>{cooperative.phone}</td>
									<td>
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="button_show">
													Dados da cooperativa
												</Tooltip>
											}
										>
											<Button
												size="sm"
												type="button"
												className="me-2"
												onClick={() => {
													handleCooperativeVisualization(cooperative.id);
												}}
											>
												<AiFillEye size={18} />
											</Button>
										</OverlayTrigger>

										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="button_address">
													Cadastrar endereço
												</Tooltip>
											}
										>
											<Button
												variant="success"
												size="sm"
												type="button"
												className="me-2"
												onClick={() => {
													handleNewCooperativeAddress(cooperative.id);
												}}
											>
												<AiOutlinePlus size={18} />
											</Button>
										</OverlayTrigger>
									</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</Card>

			<BaseModal
				size="lg"
				show={show}
				onHide={handleClose}
				title={currentCooperative?.trading_name || "Nova Cooperativa"}
				footer={<></>}
			>
				<Form onSubmit={handleNewCooperative}>
					<Container fluid className="d-flex flex-column gap-5 px-0">
						{!isNewAddress ? (
							<>
								<Row>
									<Form.Group>
										<Form.Label htmlFor="corporate-name">
											Razão Social
										</Form.Label>
										<Form.Control
											id="corporate-name"
											name="corporate_name"
											disabled={!isCreating}
											value={currentCooperative?.corporate_name}
										/>
									</Form.Group>
								</Row>
								<Row>
									<Form.Group>
										<Form.Label htmlFor="trading-name">
											Nome Fantasia
										</Form.Label>
										<Form.Control
											id="trading-name"
											name="trading_name"
											disabled={!isCreating}
											value={currentCooperative?.trading_name}
										/>
									</Form.Group>
								</Row>

								<Row>
									<Col lg="6">
										<Form.Group>
											<Form.Label htmlFor="identifier">CNPJ</Form.Label>
											<Form.Control
												id="identifier"
												name="identifier"
												disabled={!isCreating}
												value={currentCooperative?.identifier}
											/>
										</Form.Group>
									</Col>
									<Col lg="6">
										<Form.Group>
											<Form.Label htmlFor="phone">Telefone</Form.Label>
											<PhoneInput
												mask="(99) 9 9999-9999"
												id="phone"
												name="phone"
												value={currentCooperative?.phone}
											/>
										</Form.Group>
									</Col>
								</Row>
							</>
						) : (
							<>
								<Row>
									<Col lg="8">
										<Form.Group>
											<Form.Label htmlFor="street">Endereço</Form.Label>
											<Form.Control id="street" name="address" />
										</Form.Group>
									</Col>
									<Col lg="4">
										<Form.Group>
											<Form.Label htmlFor="number">Número</Form.Label>
											<Form.Control id="number" name="number" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg="6">
										<Form.Group>
											<Form.Label htmlFor="neighborhood">Bairro</Form.Label>
											<Form.Control id="neighborhood" name="district" />
										</Form.Group>
									</Col>
									<Col lg="6">
										<Form.Group>
											<Form.Label htmlFor="zipcode">CEP</Form.Label>
											<Form.Control id="zipcode" name="zip_code" />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col lg="6">
										<Form.Group>
											<Form.Label htmlFor="state">Estado</Form.Label>
											<Form.Select
												id="state"
												name="state_id"
												onChange={(event) =>
													setCurrentState(Number(event.target.value))
												}
											>
												{states.map((state) => {
													return (
														<option key={state.id} value={state.id}>
															{state.name}
														</option>
													);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col lg="6">
										<Form.Group>
											<Form.Label htmlFor="city">Cidade</Form.Label>
											<Form.Select id="city" name="city_id">
												{cities.map((city) => {
													return (
														<option key={city.id} value={city.id}>
															{city.name}
														</option>
													);
												})}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<div key={"checkbox"} className="mb-3">
										<Form.Check type={"checkbox"} id="is_main">
											<Form.Check.Input
												type="checkbox"
												name="is_main"
												value="1"
											/>
											<Form.Check.Label>
												Selecionar este endereço como principal
											</Form.Check.Label>
										</Form.Check>
									</div>
								</Row>
							</>
						)}

						{(!isCreating || isNewAddress) && (
							<Row className="mt-2">
								<Accordion>
									<Accordion.Item eventKey={"0"}>
										<Accordion.Header>
											Endereços já Cadastrados
										</Accordion.Header>
										<Accordion.Body>
											<ListGroup variant="flush">
												{cooperativeAddresses?.map((address) => {
													return (
														<ListGroup.Item
															key={address.id}
															className="d-flex align-items-center"
														>
															<Col lg="11">
																<strong> Endereço:</strong>{" "}
																{`${address.address}, ${address.number
																	} - ${address.district} - ${address?.city?.name +
																	"/" +
																	getStateName(address.state_id)
																	} - ${address.zip_code}`}
																{address.is_main && (
																	<OverlayTrigger
																		placement="top"
																		overlay={
																			<Tooltip id="main_address">
																				Endereço Principal
																			</Tooltip>
																		}
																	>
																		<Badge
																			bg="warning"
																			className="ms-2"
																		>
																			<AiOutlineCrown size="14" />
																		</Badge>
																	</OverlayTrigger>
																)}{" "}
															</Col>
															<Col lg="1">
																<Button
																	variant="danger"
																	size="sm"
																	type="button"
																	className="me-2"
																	onClick={() =>
																		handleDeleteAddress(
																			address.id
																		)
																	}
																>
																	<AiOutlineDelete size={18} />
																</Button>
															</Col>
														</ListGroup.Item>
													);
												})}
											</ListGroup>
										</Accordion.Body>
									</Accordion.Item>
								</Accordion>
							</Row>
						)}

						{(isCreating || isNewAddress) && (
							<Row className="mt-2">
								<Form.Group className="d-flex justify-content-end">
									<Button variant="primary" type="submit">
										Enviar
									</Button>
								</Form.Group>
							</Row>
						)}
					</Container>
				</Form>
			</BaseModal>
		</>
	);
}
