import React, { ReactNode } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { checkIsActive, KTSVG } from "../../../helpers";
import { useLayout } from "../../core";

type Props = {
	to: string;
	title: string;
	icon?: string | ReactNode;
	fontIcon?: string;
	hasBullet?: boolean;
};

const AsideMenuItem: React.FC<Props> = ({
	children,
	to,
	title,
	icon,
	fontIcon,
	hasBullet = false,
}) => {
	const { pathname } = useLocation();
	const isActive = checkIsActive(pathname, to);
	const { config } = useLayout();
	const { aside } = config;

	function getIcon() {
		if (!icon) {
			return;
		}

		if (React.isValidElement(icon)) {
			return <span className="menu-icon">{icon}</span>;
		}

		if (aside.menuIcon === "svg" && typeof icon === "string") {
			return (
				<span className="menu-icon">
					<KTSVG path={icon} className="svg-icon-2" />
				</span>
			);
		}

		if (aside.menuIcon === "font" && typeof icon === "string") {
			return <i className={clsx("bi fs-3", fontIcon)}></i>;
		}
	}
	return (
		<div className="menu-item">
			<Link className={clsx("menu-link without-sub", { active: isActive })} to={to}>
				{hasBullet && (
					<span className="menu-bullet">
						<span className="bullet bullet-dot"></span>
					</span>
				)}
				{getIcon()}
				<span className="menu-title">{title}</span>
			</Link>
			{children}
		</div>
	);
};

export { AsideMenuItem };
