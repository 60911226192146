/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Registration } from "../components/Registration";
import { ForgotPassword } from "../components/ForgotPassword";
import { Login } from "../components/Login";
import { toAbsoluteUrl } from "@/../_metronic/helpers";

import "./styles.scss";

const AuthLayout = () => {
	useEffect(() => {
		document.body.classList.add("bg-white");
		return () => {
			document.body.classList.remove("bg-white");
		};
	}, []);

	return (
		<div
			className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-cover bgi-attachment-fixed"
			id="auth-layout"
		>
			<div className="d-flex flex-column flex-lg-row flex-column-fluid">
				<div className="d-flex flex-column-fluid flex-column flex-center p-10 login-container">
					<div className="d-flex flex-center login-section-container">
						<div className="bg-body d-flex flex-column flex-center gap-10 w-md-600px p-10 pb-15 login-form-container">
							<div className="d-flex align-items-center justify-content-center mt-5">
								<img
									id="auth-body-logo"
									src={toAbsoluteUrl("/media/logos/logo-dark.svg")}
									alt="Indicadores ESUS - Logo azul escuro"
								/>
							</div>

							<div className="text-center mb-3">
								<h1 className="text-dark">Login</h1>
							</div>

							<div className="w-md-400px">
								<Outlet />
							</div>
						</div>
					</div>

					<div className="mt-5 d-flex flex-column justify-content-end align-items-center footer-container">
						<span className="text-white mb-3" id="product-title">
							Um produto:
						</span>
						<img
							src={toAbsoluteUrl("/media/logos/logo-multintegrada-branca.svg")}
							alt="Multintegrada - Tecnologia"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const AuthPage = () => (
	<Routes>
		<Route element={<AuthLayout />}>
			<Route path="login" element={<Login />} />
			<Route path="registration" element={<Registration />} />
			<Route path="esqueci-a-senha" element={<ForgotPassword />} />
			<Route index element={<Login />} />
		</Route>
	</Routes>
);

export { AuthPage };
