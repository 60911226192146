import { useEffect } from "react";
import { Link } from "react-router-dom";

import Card from "react-bootstrap/Card";

import { PageTitle, useLayout } from "@/../_metronic/layout/core";

export function DashboardPage() {
	const { setTitle } = useLayout();

	useEffect(() => {
		setTitle("DASHBOARD");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="row g-5 g-xl-8 pb-15">
				<div className="col-xl-12">
					<Card>
						<Card.Header className="border-0">
							<Card.Title className="align-items-start flex-column">
								<span className="card-label fw-bolder fs-3 mb-1">
									FILTRAR POR UNIDADE
								</span>
							</Card.Title>
						</Card.Header>

						<Card.Body className="pt-0">
							<select
								name="dashboard-select"
								id="dashboard-select"
								className="form-select"
							>
								<option value="0">UNIDADE 1</option>
								<option value="0">UNIDADE 2</option>
							</select>
						</Card.Body>
					</Card>
				</div>
			</div>

			<div className="row g-5 g-xl-8">
				{Array.from({ length: 7 }, (_, index) => {
					return (
						<div className="col-xl-6" key={index}>
							<div className="card">
								<div className="card-header border-0 py-5">
									<h3 className="card-title align-items-start flex-column w-100">
										<span className="card-label fw-bolder fs-3 mb-1 w-100">
											TÍTULO
										</span>
									</h3>
								</div>

								<div className="card-body d-flex flex-column align-items-center">
									<div className="flex-grow-1">
										<div className="mixed-widget-4-chart"></div>
									</div>

									<div className="w-100 pt-5">
										<p className="text-center fs-6 pb-5">
											Uma descrição qualquer
										</p>

										<Link
											to={`/pagina-de-exemplo`}
											className="btn btn-primary w-100 py-3"
										>
											Ver detalhes
										</Link>
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
}

export function DashboardWrapper() {
	return (
		<>
			<PageTitle breadcrumbs={[]}>PAINEL DE INDICADORES</PageTitle>
			<DashboardPage />
		</>
	);
}
